import { 
    Box,
    Container,
    Stack,
    Typography, 
    useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import WelcomeImage from '../../assets/img/welcome.jpg';
import WelcomeVideo from '../../assets/img/welcome.webm';

export default function Welcome () {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Container maxWidth="xl">
            <Box>
                <Stack 
                    flexDirection={ matchUpMd ? "row" : "column"} 
                    alignItems="center"
                    sx={{
                        pl: matchUpMd ? 6 : 0
                    }}
                >
                    <Stack flex={1} alignItems="flex-start" gap={4}
                        sx={{
                            borderLeft: '2px solid #5a5a5a',
                        }}
                    >
                        <Box
                            sx={{
                                pl: matchUpMd ? 6 : 2,
                                maxWidth: matchUpMd ? 340 : 'unset',
                            }}
                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    fontFamily: 'Kanit',
                                    letterSpacing: '.16em',
                                }}
                            >Welcome to Ghospers!</Typography>
                            </Box>
                        <Box
                            sx={{
                                pl: matchUpMd ? 6 : 2,
                                borderLeft: '2px solid #09c8e1',
                                maxWidth: matchUpMd ? 400 : 'unset',
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    lineHeight: '1.8em',
                                    fontFamily: 'Kanit',
                                    letterSpacing: '.08em',
                                }}
                            >We're Changing the Way the World Thinks About Gaming</Typography>
                        </Box>
                        <Box
                            sx={{
                                pl: matchUpMd ? 6 : 2,
                                maxWidth: matchUpMd ? 500 : 'unset',
                            }}
                        >
                            <Stack alignItems="flex-start" gap={3}>
                                <Typography>
                                    Immerse yourself in a fantasy world that has never existed before. 
                                    Ghostalia is the world where the magic blows through the wind. 
                                    From different elements to different Ghospers, there is nothing you won’t find! 
                                    A game for those who can’t get enough of magic, strategy, and skill-based gameplay!
                                </Typography>
                                <Typography>
                                    Explore the P2E world by fighting your way through tournaments, customizing your Ghospers, and becoming the best mage you can be!
                                </Typography>
                                <Typography>
                                    Anything is possible! 
                                </Typography>
                            </Stack>
                        </Box>
                    </Stack>
                    <Stack flex={1}>
                        <Box component="video" src={WelcomeVideo} sx={{ maxWidth: '100%'}} autoPlay muted loop="infinite" />
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
}