import * as React from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Menu,
    Container,
    Button,
    MenuItem,
    Stack,
    Link as ExternalLink,
    useMediaQuery
} from '@mui/material';
import { Link } from 'react-scroll';
import MenuIcon from '@mui/icons-material/Menu';
import LogoImage from '../assets/img/logo.png';

const pages = [
    { type: 'link', isInternal: true, title: 'Home', href: 'welcome' },
    { type: 'link', isInternal: true, title: 'Gameplay', href: 'gameplay' },
    { type: 'link', isInternal: true, title: 'Partners', href: 'partners' },
    { type: 'link', isInternal: true, title: 'NFTs', href: 'collection' },
    { type: 'link', isInternal: false, title: 'Staking', href: 'https://staking.ghospers.com/' },
    { type: 'link', isInternal: false, title: 'Gitbook', href: 'https://ghospers.gitbook.io/ghospersgame/' },
    { type: 'button', isInternal: false, title: 'Try Demo', href: 'https://drive.google.com/drive/folders/1UHZBgYHJV5wUQQoBaCyTh2yC3j-Xw00A?usp=sharing' },
];

export default function Header ({isShow}) {
    
    const matches = useMediaQuery('(min-width: 360px)');
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    
    console.log(isShow)
    return (
        <AppBar position="fixed" 
            sx={{
                background: 'transparent',
                boxShadow: 'none',
                opacity: isShow ? 1 : 0,
                transition: '.5s ease',
                // backgroundImage:

            }}
        >
            <Container maxWidth="xl">
                <Toolbar 
                    sx={{
                        justifyContent: "space-between"
                    }}
                >
                    <Box 
                        component="img" 
                        src={LogoImage}
                        sx={{
                            display: matches ? 'block' : 'none'
                        }}
                    />

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& a': {
                                    color: '#FFFFFF',
                                    textDecoration: 'none',
                                    fontSize: 16
                                }
                            }}
                        >
                        {pages.map((page, key) => (
                            <MenuItem key={key} onClick={handleCloseNavMenu}>
                                {
                                    page.type === 'link' 
                                    ?
                                        page.isInternal === false 
                                        ? (
                                            <ExternalLink
                                                key={key}
                                                href={page.href}
                                                target="_blank"
                                            >
                                                {page.title}
                                            </ExternalLink>
                                        )
                                        : (
                                            <Link
                                                key={key}
                                                to={page.href}
                                            >
                                                {page.title}
                                            </Link>
                                        )
                                    : (
                                        <ExternalLink
                                            key={key}
                                            href={page.href}
                                            target="_blank"
                                        >
                                            <Button 
                                                variant="contained" 
                                                color="info" 
                                                size="small" 
                                                sx={{ 
                                                    color: '#FFFFFF' 
                                                }}
                                            >
                                                {page.title}
                                            </Button>
                                        </ExternalLink>
                                    )
                                }
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>
                    <Stack 
                        flexDirection="row"
                        gap={3}
                        alignItems="center"
                        sx={{ 
                            display: { xs: 'none', md: 'flex' },
                            '& a': {
                                color: '#FFFFFF',
                                textDecoration: 'none',
                                fontSize: 16,
                                cursor: 'pointer'
                            }
                        }}
                    >
                        {pages.map((page, key) => 
                        page.type === 'link' 
                        ?
                            page.isInternal === false 
                            ? (
                                <ExternalLink
                                    key={key}
                                    href={page.href}
                                    target="_blank"
                                >
                                    {page.title}
                                </ExternalLink>
                            )
                            : (
                                <Link
                                    smooth={true} 
                                    duration={500} 
                                    spy={true} 
                                    key={key}
                                    to={page.href}
                                >
                                    {page.title}
                                </Link>
                            )
                        : (
                            <ExternalLink
                                href={page.href}
                                key={key}
                                target="_blank"
                            >
                                <Button 
                                    key={key}
                                    variant="contained" 
                                    color="info" 
                                    size="small" 
                                    sx={{ 
                                        color: '#FFFFFF' 
                                    }}
                                >
                                    {page.title}
                                </Button>
                            </ExternalLink>
                        )

                        )}
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
}