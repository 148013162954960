import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Scrollbar from './components/Scrollbar';
import Home from './pages/Home';
import ThemeConfig from './theme';
import './App.css';

function App() {

  const [position, setPosition] = React.useState(0)
  const [isShow, setShow] = React.useState(true);
  const setPos = (ele) => {
    setPosition(ele);
  }

  const setHeader = (ele) => {
    setShow(ele);
  }

  return (
    <div className="App">
      <ThemeConfig>
        <BrowserRouter>
          <Header isShow={isShow} />
          <Scrollbar position={position} />
          <Routes>
            <Route path="/" element={<Home setPos={setPos} setHeader={setHeader} />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeConfig>
    </div>
  );
}

export default App;
