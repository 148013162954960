import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        posAry: [
            0, -1000, -2000, -3000
        ],
        value: 1
    },
    reducers: {
        setPos: (state, action) => {
        }
    }
})

export const { setPos } = counterSlice.actions
export default counterSlice.reducer;