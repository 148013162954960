import { 
    Box,
    Typography,
    Divider,
    Stack,
    Container,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BackImage from '../../assets/img/back.jpg';

export default function Numbers () {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Box
            sx={{
                backgroundImage: `url(${BackImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: matchUpMd ? '100% auto' : 'auto',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed'
            }}
        >
            <Container maxWidth="xl">
                <Stack flexDirection="row" justifyContent="flex-end">
                    <Stack
                        sx={{
                            width: matchUpMd ? '50%' : '100%',
                            py: 25,
                            px: matchUpMd ? 10 : 0,
                        }}
                        gap={8}
                        justifyContent="center"
                    >
                        <Stack>
                            <Typography
                                variant="h4"
                                sx={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '.08em',
                                    maxWidth: 200,
                                    lineHeight: 2
                                }}
                            >Ghospers in numbers</Typography>
                        </Stack>
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Stack>
                                <Typography variant="h3" 
                                    sx={{
                                        fontWeight: 400
                                    }}
                                >10.000</Typography>
                                <Box 
                                    sx={{
                                        py: 1
                                    }}
                                >
                                    <Divider />
                                </Box>
                                <Typography>1st GENERATION NFTs</Typography>
                            </Stack>
                            <Stack>
                                <Typography
                                    variant="h3" 
                                    sx={{
                                        fontWeight: 400
                                    }}
                                >3</Typography>
                                <Box 
                                    sx={{
                                        py: 1
                                    }}
                                >
                                    <Divider />
                                </Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                    }}
                                >Battle Arenas</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}