import * as React from 'react';
import {
    Box,
    Link,
    Stack,
    Typography,
    useMediaQuery,
    OutlinedInput,
    Modal
} from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

import LogoImage from '../assets/img/logo.png';
import { 
    useTheme,
    createTheme,
} from '@mui/material/styles';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 32px)',
    bgcolor: '#FFFFFF',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
  

export default function Footer () {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const newTheme = createTheme(theme);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const matches = useMediaQuery('(min-width: 360px)');
    return (
        <Box
            sx={{
                borderTop: '2px solid #FFFFFF',
                p: 1,
                bgcolor: 'transparent'
            }}
        >
            <Stack flexDirection={ matches ? "row" : 'column'} gap={8} justifyContent="space-evenly"
            >
                <Stack alignItems="flex-start" gap={1}>
                    <Box component="img" src={LogoImage} sx={{ maxWidth: '100%'}} />
                    <Typography
                        variant="subtitle1"
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 400,
                        }}
                    >
                        <ScrollLink 
                            smooth={true} 
                            duration={2000} 
                            spy={true} 
                            to="welcome"
                            style={{ cursor: 'pointer' }}
                        >info@ghospers.com</ScrollLink>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        // onClick={handleOpen}
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 400,
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        <ScrollLink 
                            smooth={true} 
                            duration={2000} 
                            spy={true} 
                            to="welcome"
                            style={{ cursor: 'pointer' }}
                        >Join the team</ScrollLink></Typography>
                    {/* <Typography
                        variant="subtitle1"
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 400
                        }}
                    >Ghost ivy</Typography> */}
                    <Typography 
                        variant="subtitle1"
                        sx={{
                            color: '#09C8E1',
                            fontWeight: 400
                        }}
                    >TM 2022 by Ghospers Game</Typography>
                </Stack>
                <Stack
                    alignItems="flex-start"
                    sx={{
                        '& a': {
                            color: '#FFFFFF',
                            textDecorationColor: '#FFFFFF',
                            fontSize: 14,
                            fontFamily: 'Kanit'
                        }
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                            textTransform: 'uppercase',
                            color: '#09C8E1',
                        }}
                    >Subscribe</Typography>
                    {/* <Link href="https://opensea.io/collection/ghospers">Opensea</Link> */}
                    <Link href="https://pancakeswap.finance/swap?outputCurrency=0x4a0cc0876ec16428a4fa4a7c4c300de2db73b75b" target="_blank">Pancakeswap</Link>
                    <Link href="https://twitter.com/GhospersGame" target="_blank">Twitter</Link>
                    <Link href="https://t.me/ghospersgamepublic" target="_blank">Telegram chat</Link>
                    <Link href="https://t.me/Ghospersgame" target="_blank">Telegram Announcements</Link>
                    <Link href="https://t.co/f6iL5goH0d?amp=1" target="_blank">Discord</Link>
                    <Link href="https://www.facebook.com/GhospersGame/" target="_blank">Facebook</Link>
                    <Link href="https://instagram.com/ghospers.game?utm_medium=copy_link" target="_blank">Instagram</Link>
                    <Link href="https://vm.tiktok.com/ZMLdmYCcN/" target="_blank">Tiktok</Link>
                    <Link href="https://bscscan.com/address/0x4a0Cc0876EC16428A4Fa4a7C4c300dE2DB73b75b" target="_blank">Contract Address(BSC Scan)</Link>
                </Stack>
            </Stack>
            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={newTheme}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h3">
                            Want to Join us
                        </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                If you're unreal, out-of-the-world, super cool ENgine Developer, Designer, and/or creative team player, please send your CV and cover letter and tell us why you'd make the perfect candidate.
                            </Typography>
                        <Stack>
                        
                        <Stack>
                            <OutlinedInput />
                        </Stack>
                        <Stack>
                            <OutlinedInput />
                        </Stack>
                        <Stack>
                            <OutlinedInput />
                        </Stack>
                        <Stack>
                            <OutlinedInput />
                        </Stack>
                        <Stack>
                            <OutlinedInput />
                        </Stack>
                    </Stack>
                    </Box>
                </ThemeProvider>
            </Modal> */}
        </Box>
    );
}