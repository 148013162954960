import { 
    Stack, 
    Box, 
    Typography, 
    Button,
    Container,
    useMediaQuery 
} from "@mui/material";
import { Link } from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import NFTImage from '../../assets/img/nft.png';
import HumanImage from '../../assets/img/human.png';
import PancakeImage from '../../assets/img/pancake.png';
import BscImage from '../../assets/img/bsc.png';
import ThreeImage from '../../assets/img/bbz.png';
import GhostImage from '../../assets/img/ghost.png';

export default function Partners () {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    
    return (
        <Container maxWidth="xl" id="partners" sx={{ py: 10 }}>
            <Box>
                <Stack flexDirection={matchUpMd ? "row" : "column"} gap={matchUpMd ? 0 : 8}>
                    <Box flex={1}
                        sx={{
                            pl: matchUpMd ? 6 : 0,
                        }}
                    >
                        <Stack
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{
                                borderLeft: '2px solid #5a5a5a',
                                maxWidth: matchUpMd ? 340 : 'unset',
                                height: 188
                            }}
                        >
                            <Box
                                sx={{
                                    pl:matchUpMd ? 6 : 2,
                                    borderLeft: '2px solid #09c8e1',
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontFamily: 'Kanit',
                                        letterSpacing: '.16em',
                                    }}
                                >Partners & Investors</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Stack flex={2} gap={10}>
                        <Stack 
                            flexDirection={matchUpSm ? "row": 'column'} 
                            justifyContent="space-evenly" 
                            alignItems="center"
                            gap={matchUpSm ? 0 : 10}
                        >
                            <Box component="img" src={ThreeImage} sx={{ maxWidth: '100%' }} />
                            <Box component="img" src={NFTImage} sx={{ maxWidth: '100%' }} />
                            <Box component="img" src={HumanImage} sx={{ maxWidth: '100%' }} />
                        </Stack>
                        <Stack 
                            flexDirection={matchUpSm ? "row": 'column'} 
                            justifyContent="space-evenly" 
                            alignItems="center"
                            gap={matchUpSm ? 0 : 10}
                        >
                            <Box component="img" src={BscImage} sx={{ maxWidth: '100%' }} />
                            <Box component="img" src={PancakeImage} sx={{ maxWidth: '100%' }} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack alignItems="center" gap={5}>
                    <Box sx={{
                        pt: 20
                    }}>
                        <Typography 
                            variant="h3"
                            sx={{
                                fontWeight: 800,
                                fontFamily: 'Kanit',
                                letterSpacing: '.08em',
                            }}
                        >Game Developer</Typography>
                    </Box>
                    <Box>
                        <Box component="img" src={GhostImage} sx={{ maxWidth: '100%'}} />
                    </Box>
                    <Stack alignItems="center" justifyContent="center">
                        {/* <Link
                            smooth={true} 
                            duration={1000} 
                            spy={true} 
                            to="welcome"
                            offset={-50}
                        >
                            <Button variant="contained" color="info">Discover More</Button>
                        </Link> */}
                    </Stack>
                </Stack>
            </Box>
        </Container>

    );
}