const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 992, // OLD 960
        lg: 1400, // OLD 1280
        xl: 1920 // OLD 1920
    }
};
  
export default breakpoints;
  