import { 
    Typography,
    Stack,
    Button,
    Box,
    useMediaQuery,
    Link,
    CardMedia
} from "@mui/material";
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import CircleImage from '../../assets/img/circle.png';
import PlayImage from '../../assets/img/play.png';
import PlayVideo from '../../assets/img/play.mp4';
import BackVideo from '../../assets/img/back.webm';

export default function Dashboard () {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box
            sx={{
                pt: 18,
                position: 'relative',
                height: matchUpMd ? 'unset' : (matchUpSm ? '56.25vw' : 'unset'),
            }}
            id="welcome"
        >
            <CardMedia 
                component="video"
                image={BackVideo}
                autoPlay
                muted
                loop="infinite"
                sx={{
                    position:"absolute",
                    top: 0,
                    width: '100%',
                }}
            />
            <Stack alignItems="center" gap={8}>
                <Stack gap={2}>
                    <Typography 
                        variant="h3"
                        sx={{
                            fontFamily: 'Kanit',
                            fontWeight: 400,
                            letterSpacing: '.08em',
                            maxWidth: 800,
                            textAlign: 'center',
                            zIndex: 1 
                        }}
                    >Get Ready to Fight with the best Magicians in Ghostalia!</Typography>
                    <Stack 
                        flexDirection={ matchUpSm ? "row" : "column" }
                        justifyContent="center"
                        alignItems="center"
                        gap={matchUpMd ? 8 : 2}
                        sx={{
                            '& a': {
                                textDecoration: 'none'
                            },
                            '& button': {
                                color: '#FFFFFF',
                                '&:hover' : {
                                    color: '#000'
                                }
                            }
                        }}
                    >
                        <Link href="https://marketplace.ghospers.com" target="_blank">
                            <Button variant="contained" color="info" size="large">Trade NFTs</Button>
                        </Link>
                        <Link href="https://pancakeswap.finance/swap?outputCurrency=0x4a0cc0876ec16428a4fa4a7c4c300de2db73b75b" target="_blank"><Button variant="contained" color="info" size="large">Buy $GHSP</Button></Link>
                        <Link href="https://staking.ghospers.com/" target="_blank"><Button variant="contained" color="info" size="large">Stake Now</Button></Link>
                    </Stack>
                </Stack>
                <Box sx={{ zIndex: 1 }}>
                    <Box>
                        <Box
                            sx={{
                                display: matchUpMd ? 'block' : 'none',
                                width: 400,
                                height: 400
                            }}
                        >

                        </Box>
                        {/* <CardMedia 
                            component="video"
                            image={PlayVideo}
                            autoPlay
                            muted
                            loop="infinite"
                            sx={{
                                maxWidth: 400,
                                width: '100%',
                                top: '50%',
                                backgroundImage: `url(${PlayImage})`,
                                backgroundPosition: '100% 100%'
                            }}
                            // sx={{
                            //     position:"absolute",
                            //     top: 0,
                            //     width: '100%',
                            //     height: '100%',
                            //     // zIndex: 2000
                            // }}
                        /> */}
                        {/* <Box component="video" sx={{ width: 400, height: 400 }}  autoPlay={true}>
                            <Box component="source" src={PlayVideo} type="video/mp4" />
                        </Box>
                        <Box component="img" src={CircleImage} 
                            sx={{
                                backgroundImage: `url(${PlayImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                maxWidth: '100%'
                                // backgroundSize: '100%',
                            }}
                        >
                        </Box> */}
                        {/* <Box component="img" src={PlayImage} /> */}
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
}