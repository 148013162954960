import * as React from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Dashboard from "./Dashboard";
import Welcome from "./Welcome";
import Gameplay from "./Gameplay";
import Partners from "./Partners";
import Numbers from "./Numbers";
import Collection from "./Collection";

export default function Home ({setPos, setHeader}) {
    const barRef = React.useRef(null)
    const [prevPos, setPrev] = React.useState(0);
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return function cleanup() {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    const handleScroll = () => {
        const homeEle = barRef.current.getBoundingClientRect()
        const one = parseInt(homeEle.height / 4)
        setPos(Math.abs(parseInt(homeEle.y / one - 0.5)) > 3 ? 3 : Math.abs(parseInt(homeEle.y / one - 0.5)))

        //------------header---------------------
        if(homeEle.y > prevPos) {
            setHeader(true);
        } else if(homeEle.y > -100) {
            setHeader(true)
        } else {
            setHeader(false)
        }
        setPrev(homeEle.y)
        
    }

    return (
        <Stack gap={matchUpMd ? 10 : 0} ref={barRef}
            sx={{
                '&::-webkit-scrollbar' : {
                    width: 10,
                },
                '&::-webkit-scrollbar-track' : {
                    background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb' : {
                    background: '#888',
                    '&:hover' : {
                        background : '#555',
                    }
                },
            }}
        >
            <Dashboard />
            <Welcome />
            <Gameplay />
            <Partners />
            <Numbers />
            <Collection />
        </Stack>
    );
}