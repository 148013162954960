import { 
    Stack, 
    Typography, 
    Box,
    Container,
    useMediaQuery
} from "@mui/material";
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Gallery1Image from '../../assets/img/gallery/1.webp';
import Gallery2Image from '../../assets/img/gallery/2.webp';
import Gallery3Image from '../../assets/img/gallery/3.webp';
import Gallery4Image from '../../assets/img/gallery/4.webp';
import Gallery5Image from '../../assets/img/gallery/5.webp';

const cards = [
    {
        back: Gallery1Image,
        title: 'PvP',
        content: 'There is no shortage of PvP in this world. Whether a tournament or a level, you must be ready!'
    },
    {
        back: Gallery2Image,
        title: 'NFT Ownership',
        content: 'As you explore the world with your Ghosper you will encounter enemies, fight them and level up your NFT!'
    },
    {
        back: Gallery3Image,
        title: 'PvE',
        content: "Get to know the world with your Ghosper, explore Arena's, make friends and fight enemies!",
    },
    {
        back: Gallery4Image,
        title: 'Storyline',
        content: 'The Ghospers are mysterious creatures, explore their background and world by following the game.'
    },
    {
        back: Gallery5Image,
        title: 'P2E',
        content: 'The more you win the more you can earn, Ghospers Game gives back to the community!'
    }
]
export default function Gameplay ({setPos}) {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    
    return (
        <Container maxWidth="xl" id="gameplay">
            <Box>
                <Stack
                    sx={{
                        pl: matchUpMd ? 6 : 0,
                    }}
                >
                    <Stack
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{
                            borderLeft: '2px solid #5a5a5a',
                            maxWidth: matchUpMd ? 340 : 'unset',
                            height: 188
                        }}
                    >
                        <Box
                            sx={{
                                pl:matchUpMd ? 6 : 2,
                                borderLeft: '2px solid #09c8e1',
                            }}
                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    fontFamily: 'Kanit',
                                    letterSpacing: '.16em',
                                }}
                            >Gameplay</Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        pt: 4,
                        px: matchUpMd ? 2 : 0
                    }}
                >
                    <Stack
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: matchUpLg 
                                                ? 'repeat(5, 20%)'
                                                : matchUpMd
                                                ? 'repeat(3, 33%)'
                                                : matchUpSm
                                                ? 'repeat(2, 50%)'
                                                : 'repeat(1, 100%)'
                        }}
                    >
                    {cards.map((element, key) => 
                        <Box
                            key={key} 
                            sx={{
                                p: 1,
                                height: matchUpLg
                                        ? '25vw'
                                        : matchUpMd
                                        ? '40vw'
                                        : matchUpSm
                                        ? '60vw'
                                        : '120vw'
                            }}
                        >
                            <Box 
                                sx={{
                                    height: '100%',
                                    background: `url(${element.back}), rgba(0,0,0,.5)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center, center',
                                    backgroundSize: '100% 100%',
                                    borderRadius: 20,
                                    '& p': {
                                        textAlign: 'center'
                                    },
                                    '&:hover': {
                                        opacity: .5
                                    }
                                }}
                            >
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        height: '100%',
                                        backgroundColor: 'rgba(0,0,0,.3)',
                                        transition: '.5s ease',
                                        py: 6,
                                        px: matchUpMd ? 3 : 2,
                                        '&:hover': {
                                            opacity: 0,
                                        }
                                    }}
                                >
                                    <Typography variant="h3"
                                        sx={{
                                            fontWeight: 400,
                                            textAlign: 'center',
                                        }}
                                    >{element.title}</Typography>
                                    <Typography sx={{ lineHeight: 1.4 }}>{element.content}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    )}  
                    </Stack>
                </Box>

            </Box>
        </Container>
    );
}