import { 
    Box,
    Button,
    Stack,
    Typography,
    Container,
    Link,
    useMediaQuery,
    CardMedia
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import CollectionImage from '../../assets/img/collection.jpg';
import CollectionVideo from '../../assets/img/collection.webm';

export default function Collection () {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Container maxWidth="xl" id="collection">
            <Box sx={{
                py: 16,
                px: matchUpMd ? 6 : 0,
                pb: 20
            }}>
                <Stack flexDirection={matchUpMd ? "row" : "column"} alignItems="center" gap={ matchUpMd ? 0 : 8 }>
                    <Stack 
                        flex={1} 
                        sx={{
                            borderLeft: '2px solid #5a5a5a',
                            py: 4
                        }}
                        justifyContent="center" 
                        alignItems="flex-start"
                    >
                        <Stack
                            justifyContent="center"
                            alignItems="flex-start"
                            gap={8}
                            sx={{
                                maxWidth: matchUpMd ? 500 : 'unset',
                            }}
                        >
                            <Box
                                sx={{
                                    pl:matchUpMd ? 6 : 2,
                                    borderLeft: '2px solid #09c8e1',
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontFamily: 'Kanit',
                                        letterSpacing: '.16em',
                                    }}
                                >Explore our 10,000 Ghospers NFT Collection</Typography>
                            </Box>
                            <Box
                                sx={{
                                    pl:matchUpMd ? 6 : 2,
                                }}
                            >
                                <Link href="https://marketplace.ghospers.com" target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button variant="contained" color="info">Trade NFTs</Button>
                                </Link>
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack flex={1} sx={{
                        px: matchUpMd ? 10 : 0
                    }}>
                        <CardMedia 
                            component="video"
                            image={CollectionVideo}
                            autoPlay
                            muted
                            loop="infinite"
                            sx={{
                                borderRadius: 8,
                                maxWidth: '100%',
                                backgroundImage: `url${CollectionImage}`,
                                backgroundSize: '100% 100%',
                            }}
                            // sx={{
                            //     maxWidth: 400,
                            //     width: '100%',
                            // }}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
}