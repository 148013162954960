import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-scroll';

const points = [
    { index: 0, to: 'welcome'},
    { index: 1, to: 'gameplay'},
    { index: 2, to: 'partners'},
    { index: 3, to: 'collection'}
];
export default function Scrollbar ({position}) {
    return (
        <Box
            sx={{
                position: 'fixed',
                right: 24,
                top: 'calc(50% - 48px)',
                zIndex: 1200
            }}
        >
            {points.map((element, key) => 
            <Stack 
                key={key}
                justifyContent="center"
                sx={{ 
                    width: 24, 
                    height: 24, 
                    borderRadius: '50%' ,
                    border: position == element.index ? '1px solid #09c8e1' : 0,
                    // transition: 'border .25s ease-out',
                }}
            >
                <Link
                    smooth={true} 
                    duration={500} 
                    spy={true} 
                    key={key}
                    to={element.to}
                >
                    <Box 
                        sx={{ 
                            width: 10, 
                            height: 10, 
                            m: 'auto', 
                            cursor: 'pointer',
                            bgcolor: position == element.index ? '#FFFFFF' : '#09c8e1', 
                            borderRadius: '50%',
                            transition: 'background-color .5s ease',
                    }}
                    ></Box>
                </Link>
            </Stack>
            )}
        </Box>
    );
}